import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SocialMediaBox from '../components/social-media-box'
import '../styles/global.css'



export default function About() {

  return (

    <Layout>

      <SEO />

      <SocialMediaBox />

      <div className="about-section">
        <h1 className="main-heading">OEM+ Auto Enthusiast Community</h1>
        <p>We are aiming to build a community of auto enthusiasts that appreciate cars in any form. That means from bone stock, to lightly modified OEM+ style and all the way to a stripped down race car or show car. There's a lot of awesome builds out there that were built with the blood sweat and tears of passionate owners. Our main goal is to foster a community for car owners that are just as passionate but prefer other more subtle ways to appreciate cars that often go unnoticed and appreciate any form of rolling art work.</p>
      </div>

      <div className="about-section">
        <h3>What are OEM+ Modifications</h3>
        <p>While there's a huge, ever-growing aftermarket for car parts, there's something cool about finding a compatible upgrade for your car from within the same car family or manufacturer. Most times, this means finding lightly used parts in your local market for cheap that bolt on directly. For example, instead of opting for aftermarket wheels, you could find a set of hard-to-find optional wheels from a higher trim level of your own car.</p>
      </div>

      <div className="about-section">
        <h4>Aftermarket Parts Are Cool Too</h4>
        <p>There's nothing wrong with aftermarket parts in fact, sometimes its the only option and we always recommend you keep the OEM part so someday you can just swap the original part back in. This has multiple benefits. If you ever want to sell your car, 99% of the time modifications don't actually add value to your car. Sometimes it even lowers the value of your car, especially if you didn't use high quality parts. Instead of selling the OEM part after you go aftermarket, you could slap the original part back on and sell the aftermarket part, which is usually worth more money. It requires some self control and storage room but it's almost always the smart move financially. When you save money doing little stuff like this, it adds up, which leaves more room in the budget for more parts - it's a win-win.</p>
      </div>

    </Layout>
  )
}